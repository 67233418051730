import React from 'react';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import { PayWhatYouCanPageLayout } from '~src/modules/pay-what-you-can';

const seo = {
  seo_title: 'Pay What You Can | Aleph Beta',
  seo_description: '', // FIXME TODO
  seo_keywords: '' // FIXME TODO
};

const PayWhatYouCan = () => (
  <Page>
    <SEO {...seo} />
    <PayWhatYouCanPageLayout />
  </Page>
);

export default PayWhatYouCan;
