import React from 'react';
import {
  Impact,
  PaymentBlock,
  PremiumFeaturesHeader,
  PremiumPromo,
  ProducersCirclePromo,
  Testimonials
} from './components';

export default () => (
  <>
    <PaymentBlock />
    <PremiumFeaturesHeader />
    <Testimonials underline />
    <Impact />
    <ProducersCirclePromo />
    <PremiumPromo />
  </>
);
